.offerContainer {
  // padding: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .itemContainer {
    position: relative;
    width: 550px;
    border: 2px solid rgba(37, 3, 3, 0.37);
    background-color: #282828;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    .itemImage {
      max-width: 62px;
      background-color: #000;
      padding: 2px;
      display:inline-block;
      img {
        width: 58px;
        height: 55px;
      }
    }
    .image {
      margin: 0.5rem;
    }
    .itemTitle {
      margin-left: 1rem;
    }
  }
  .itemStatus {
    position: relative;
    .status-block {
      bottom: -31px;
      left: 16px;
    }
  }
  .actionBtn {
    margin: 55px 1rem 10px 1rem;
    width: 135px;
    font-weight: bold;
  }
  :global(.ant-typography) {
    color: #fff;
    margin-bottom: 5px;
  }
}
