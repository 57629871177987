.tooltip {
  :global {
    .ant-tooltip-content {
      width: auto;
    }
  }
}

.uploadFile {
  :global {
    .uploadShow {
      .ant-upload {
        display: block;
      }
    }
    .uploadHide {
      .ant-upload {
        display: none;
      }
    }
    .errorMessage {
      margin-top: 5px;
      color: #ff4646;
    }
    .uploadItemCustom {
      padding-top: 5px;
      .icon {
        width: 12px;
        height: 14px;
        margin-right: 5px;
      }
      .item-text {
        display: inline-block;
        width: 100%;
        padding-left: 22px;
        overflow: hidden;
        line-height: 1.5715;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 5px;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    .ant-upload.ant-upload-disabled > .upload-row > .upload-btn {
      background-color: #e7e7e7;
    }
  }
}

.textField {
  :global {
    .ant-input[disabled] {
      color: rgba(0, 0, 0, 0.25);
      font-weight: normal;
    }
  }
}

.numberField {
  :global {
    .ant-input-number {
      width: 100%;
      border-radius: 5px;
    }
  }
}

.messageError {
  font-size: 0.78rem;
  padding-left: 1px;
  white-space: nowrap;
  float: left;
}
.iconRangePicker {
  color: #bdb7b7b7;
}

.textNote {
  color: #a8a8a8;
  float: left;
}
.whiteSpace {
  white-space: break-spaces;
}
.radioField {
  :global {
    .ant-radio-wrapper {
      margin-top: 10px !important;
      min-width: 200px;
      @media screen and (max-width: 767px) {
        min-width: 107px;
        width: unset;
        margin-right: unset;
      }
    }
  }
}


.label {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 5px;
}

.preview {
  .arrayElement {
    background-color: #f1f1f1;
    padding: 0.5rem 1rem 0.25rem 1rem;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .preText {
    margin-bottom: 0px;
    max-width: 100%;
    white-space: break-spaces;
  }

  .previewCheckbox {
    :global {
      .ant-checkbox-inner {
        background-color: #989797;
      }
      .ant-checkbox-inner::after {
        border-color: #ffffff;
      }
    }
  }

  & :global {
    .ant-checkbox-wrapper {
      margin-left: 0px !important;
    }
  }
}
