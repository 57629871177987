.itemContainer {
  position: relative;
  border: 2px solid rgba(37, 3, 3, 0.37);
  border-radius: 5px;
  //background: url('/assets/images/mall_item_wnd_focus.bmp') no-repeat;
  //background-size: cover;
  //margin-bottom: 15px;
  //cursor: pointer;
  background-color: #282828;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  width:100%;
  .itemImage {
    max-width: 62px;
    background-color: #000;
    padding: 2px;
    display:inline-block;
    img {
      width: 58px;
      height: 55px;
    }
  }
  .itemDetail {
    display:inline-block;
    padding: 5px;
    .detail {
      color: #d6d6d6;
      font-size: 11px;
      .name {
        font-size: 18px;
        font-weight: bold;
      }
      .price {
        font-size: 14px;
        color: rgb(33, 192, 33);
        font-weight: bold;
      }
      .label, .value {
        font-size: 14px;
        color: #d6cfcf;
        font-weight: bold;
      }
    }
  }
  .itemDisable {
    position: absolute;
    background: #292424;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.buttonBuy {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/assets/images/btn_background_img_1.bmp') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .actionIcon {
    margin-top: 9px;
    background: #3d4c6b;
  }
}
.buttonBuy:hover {
  background: url('/assets/images/btn_background_img_1_hover.bmp') no-repeat;
  background-size: cover;
  .actionIcon {
    background: #397096;
  }
}

$width: 32px;
$heigh: 32px;
$widthNew: 64px;
$heighNew: 64px;
$animationDuration: 1.5s;
$row: 4;
$column: 8;
.effect {
  position: absolute;
  top: 15px;
  left: 20px;
  background: url('/assets/images/icon_edge_rare_nb.png');
  width: $width;
  height: $heigh;
  animation: sprintOnX $animationDuration/$row steps($column) infinite,
             sprintOnY $animationDuration steps($row) infinite;
  transform: scale(2);
}
@keyframes sprintOnX {
  from { background-position-x: 0; }
  to { background-position-x: -$width * $column; }
}
@keyframes sprintOnY {
  from { background-position-y: 0; }
  to { background-position-y: -$heigh * $row; }
}

.itemDetailContainer {
  position: relative;
  height: 100%;
  .effect {
    top: 3px;
    left: -10px;
  }
  .itemImage {
    position: absolute;
    top: 10px;
    width: 60px;
    height: 60px;
  }
  .itemDetail {
    margin-left: 70px;
    padding-top: 5px;
    .itemName {
      font-size: 20px;
      color: #d6d6d6;
      font-weight: bold;
    }
    .itemFieldContainer {
      margin-top: 10px;
      max-height: 115px;
      overflow-y: auto;
      .itemFields {
        display: flex;
        flex-direction: column;
        width: 80%;
        .itemField {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .footerModal {
    position: absolute;
    bottom: 0;
    width: 100%;
    .footerContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .actions {
        display: flex;
        .btnAction {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          background: url('/assets/images/button.bmp') no-repeat;
          background-size: 81px 30px;
          width: 81px;
          height: 30px;
          color: #d6d6d6;
          font-size: 12px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  .label {
    font-size: 15px;
    color: #d6cfcf;
    font-weight: bold;
  }
  .value {
    font-size: 15px;
    color: #359f1f;
    font-weight: bold;
    margin-left: 5px;
  }
}
