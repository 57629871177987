.characterUserInfo{
  width: 100%;
  border: 7px double #94877f;
  //border-radius: 10px;
  background-color: #000;
  color: #e6af14;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.bigText{
  font-size: 1.7rem;
  font-weight: bold;
}

.infoText{
  font-size: 1rem;
  color: #fff;
  margin-bottom: 2px;
}

.memberTypeTextNormal{
  font-size: 1.2rem;
  color: #fff;
  font-weight: 300;
  border: 2px solid #fff;
  border-radius: 5px;
}

.characterContainer {
    display: flex;
    flex-direction: column;
}

.characterBox {
  // padding: 10px;
  // padding-top: 0px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid rgb(226, 226, 226);
  .name {
    background: #1677ff;
    border: 1px solid #1677ff;
    border-radius: 5px 5px 0px 0px;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }
  .characterInfo {
    padding: 10px 15px;
    width: 100%;
    .avatarImg {
      width: 60px;
      height: 60px;
    }
    .characterDetail {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      .title {
        font-size: 15px;
        font-weight: 700;
        margin-right: 5px;
      }
    }
  }
  .characterFooter {
    padding-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    border-top: 1px solid rgb(226, 226, 226);
  }
}
.loading {
  position: fixed;
  top: -20px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 9999;
}
.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

:global {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        .ant-radio-group {
          width: 100%;
          .ant-space {
            width: inherit;
            .ant-space-item {
              .ant-radio-wrapper {
                width: 100%;
                .select-character-option {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  .avatar-img {
                    width: 60px;
                    height: 60px;
                  }
                  .character-detail {
                    display: flex;
                    flex-direction: column;
                    margin-left: 20px;
                    .title {
                      font-size: 15px;
                      font-weight: 700;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}